<template>
  <v-container class="print-body" ref="reportElem">
    <v-row>
      <v-col cols="12">
        <p class="text-center font-weight-light text-h3">
          Diagramm Generator (Beta V.0.1.0)
        </p>
      </v-col>
    </v-row>
    <v-row class="mt-5 hide-at-print">
      <v-col>
        <v-card>
          <v-card-title>
            Formular zur Erstellung eines neuen Diagrammes
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Diagrammtitel"
                  v-model="diagrammtitel"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n10">
              <v-col cols="3">
                <v-checkbox
                  v-model="beginAtZero"
                  label="Diagramm beginnt bei 0"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row class="mt-n9">
              <v-col>
                <v-radio-group v-model="wochendiagramm" row>
                  <v-radio label="Wochendiagramm" :value="true"></v-radio>
                  <v-radio label="Quartalsdiagramm" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="mt-n5">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-title>Diagrammlinien hinzufügen</v-card-title>
          <v-card-text
            v-for="dataReqest in dataReqests"
            :key="dataReqests.indexOf(dataReqest)"
          >
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Linienbeschriftung (Bitte gut beschreiben)"
                  v-model="dataReqest.labels[0]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n10">
              <v-col>
                <v-radio-group v-model="dataReqest.Companyrequest" row>
                  <v-radio label="Unternehmensabfrage" :value="true"></v-radio>
                  <v-radio
                    label="Durchschnittsabfrage"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="wochendiagramm" class="mt-n5">
              <v-col>
                <v-select
                  outlined
                  label="Eine API wählen"
                  v-if="dataReqest.Companyrequest"
                  v-model="dataReqest.selectedAPI"
                  item-text="description"
                  :items="companyWeekAPIs"
                  return-object
                  @change="changeAPI(dataReqest)"
                >
                </v-select>
                <v-select
                  outlined
                  label="Eine API wählen"
                  v-if="!dataReqest.Companyrequest"
                  v-model="dataReqest.selectedAPI"
                  item-text="description"
                  :items="averageWeekAPIs"
                  return-object
                  item-value="path"
                  @change="changeAPI(dataReqest)"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="!wochendiagramm" class="mt-n5">
              <v-col>
                <v-select
                  outlined
                  label="Eine API wählen"
                  v-if="dataReqest.Companyrequest"
                  v-model="dataReqest.selectedAPI"
                  item-text="description"
                  :items="companyQuarterAPIs"
                  return-object
                  @change="changeAPI(dataReqest)"
                >
                </v-select>
                <v-select
                  outlined
                  label="Eine API wählen"
                  v-if="!dataReqest.Companyrequest"
                  v-model="dataReqest.selectedAPI"
                  item-text="description"
                  :items="averageQuarterAPIs"
                  return-object
                  item-value="path"
                  @change="changeAPI(dataReqest)"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n5">
              <v-col>
                <v-select
                  outlined
                  label="Bitte ein Jahr auswählen"
                  :items="yearsToSelect"
                  v-model="dataReqest.apiQuerryData.year"
                ></v-select>
              </v-col>
              <v-col v-if="dataReqest.Companyrequest">
                <v-select
                  outlined
                  label="Bitte ein Unternehmen auswählen"
                  v-model="dataReqest.apiQuerryData.email"
                  item-text="companyName"
                  :items="allUsers"
                  item-value="email"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row
              class="mt-n5"
              v-if="dataReqest.selectedAPI.subVariables !== undefined"
            >
              <v-col>
                <v-select
                  outlined
                  label="Bitte eine der verfügbaren variablen Auswählen"
                  v-model="dataReqest.subVariable[0]"
                  item-text="description"
                  :items="dataReqest.selectedAPI.subVariables"
                  item-value="variable"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row
              class="mt-n5 mb-7"
              v-if="dataReqest.selectedAPI.filterOption"
            >
              <v-col>
                <filter-generator-charts
                  v-model="dataReqest.apiQuerryData.filter"
                  :showButton="false"
                  :slideMax="200"
                >
                </filter-generator-charts>
              </v-col>
            </v-row>
            <v-row class="mt-n4 mb-5">
              <v-col>
                <v-divider color="accent"></v-divider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col>
                <v-btn block color="green" @click="addLine">
                  Weitere Linie hinzufügen
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  label="Request structure (Bitte lokal zwischenspeichern)"
                  outlined
                  v-model="dataReqestsString"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-n9">
              <v-col>
                <v-btn block color="blue" @click="generateChart">
                  Diagramm generieren
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="cahrtData !== null" class="mt-10">
      <v-col>
        <v-card class="pa-4" :loading="loadingData">
          <v-card-title>
            {{ diagrammtitel }}
          </v-card-title>
          <v-card-text v-if="!loadingData">
            <custom-chart
              v-if="wochendiagramm"
              :chartdata="cahrtData"
              :beginAtZero="beginAtZero"
            />
            <custom-bar-chart
              v-if="!wochendiagramm"
              :chartdata="cahrtData"
              :beginAtZero="beginAtZero"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          class="mt-5 hide-at-print"
          color="primary"
          block
          @click="exportPDF"
        >
          Bericht als PDF Speichern
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import generateChartData from "../../utils/generateChartData";
import FilterGeneratorCharts from "../../components/adminEvaluationCharts/FilterGeneratorCharts.vue";
import apiLib from "../../assets/apiLib.js";

import customChart from "../../components/customChart.vue";
import CustomBarChart from "../../components/customBarChart.vue";

export default {
  components: { FilterGeneratorCharts, customChart, CustomBarChart },
  data() {
    return {
      diagrammtitel: null,
      loadingData: false,
      cahrtData: null,
      beginAtZero: true,
      allUsers: [],
      yearsToSelect: [],
      wochendiagramm: true,
      averageWeekAPIs: apiLib.averageWeekAPIs,
      averageQuarterAPIs: apiLib.averageQuarterAPIs,
      companyWeekAPIs: apiLib.companyWeekAPIs,
      companyQuarterAPIs: apiLib.companyQuarterAPIs,
      dataReqests: [
        {
          selectedAPI: {},
          Companyrequest: true,
          apiQuerryPath: null,
          apiQuerryData: {
            filter: {},
            year: null,
            email: null,
          },
          labels: [null],
          subVariable: [null],
          tooltipInfo: null,
        },
      ],
      dataReqestsString: "",
    };
  },
  methods: {
    exportPDF: async function () {
      window.print();
    },
    addLine: async function () {
      this.dataReqests.push(
        JSON.parse(JSON.stringify(apiLib.dataReqestsTemplate))
      );
    },
    generateYearsToSelectArray: function (
      yearPreset = new Date().getFullYear()
    ) {
      let years = [];
      for (let i = 0; i < 7; i++) {
        years.push(yearPreset - i);
      }
      return years;
    },
    changeAPI: function (dataReqest) {
      dataReqest.apiQuerryPath = dataReqest.selectedAPI.path;
      dataReqest.tooltipInfo = dataReqest.selectedAPI.tooltipInfo;
    },
    generateChart: async function () {
      this.loadingData = true;
      if (!this.wochendiagramm) {
        this.cahrtData = await generateChartData(this.dataReqests, "Quartal ");
      } else {
        this.cahrtData = await generateChartData(this.dataReqests);
      }
      this.loadingData = false;
    },
  },
  watch: {},
  async mounted() {
    this.yearsToSelect = this.generateYearsToSelectArray();
    axios
      .post(this.$store.state.apiUrl + "/api/admin/getAllUsers")
      .then((res) => {
        this.allUsers = res.data.allUser;
      });
  },
};
</script>

<style>
@media print {
  canvas {
    max-width: 100%;
    width: auto !important;
    size: landscape;
  }
  .new-page {
    page-break-before: always !important;
  }
  .hide-at-print {
    display: none !important;
  }
}
@page {
  size: landscape !important;
}
</style>
