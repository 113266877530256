const apiLib = {
  averageQuarterAPIs: [
    {
      path: "/api/admin/getAverageCartValueYearQuartal",
      description: "Durchschnittlicher Wahrenkorbwert nach Jahr",
      subVariables: [{ variable: "cartValue", description: "Warenkorbwert" }],
      tooltipInfo: {
        variable: "count",
        appendText: " Einträge ergeben diesen Durchschnitt",
      },
      filterOption: true,
    },
    {
      path: "/api/admin/getSelectedAverageYearQuartal",
      description: "Durchschnittliche Umsätze nach Jahr filterbar",
      subVariables: [
        {
          variable: "nettoUmsObstGemKartof",
          description: "Netto-Umsatz Obst, Gemüse und Kartoffeln",
        },
        {
          variable: "nettoUmsSonstige",
          description: "Netto-Umsatz Sonstiges",
        },
        {
          variable: "summe",
          description: "Summe aller Umsätze dieser Woche.",
        },
      ],
      tooltipInfo: {
        variable: "count",
        appendText: " Einträge ergeben diesen Durchschnitt",
      },
      filterOption: true,
    },
  ],
  averageWeekAPIs: [
    {
      path: "/api/admin/getAverageCartValueYear",
      description: "Durchschnittlicher Wahrenkorbwert nach Jahr",
      subVariables: [{ variable: "cartValue", description: "Warenkorbwert" }],
      tooltipInfo: {
        variable: "count",
        appendText: " Einträge ergeben diesen Durchschnitt",
      },
      filterOption: true,
    },
    {
      path: "/api/admin/getSelectedAverageYear",
      description: "Durchschnittliche Umsätze nach Jahr filterbar",
      subVariables: [
        {
          variable: "nettoUmsObstGemKartof",
          description: "Netto-Umsatz Obst, Gemüse und Kartoffeln",
        },
        {
          variable: "nettoUmsSonstige",
          description: "Netto-Umsatz Sonstiges",
        },
        {
          variable: "summe",
          description: "Summe aller Umsätze dieser Woche.",
        },
      ],
      tooltipInfo: {
        variable: "count",
        appendText: " Einträge ergeben diesen Durchschnitt",
      },
      filterOption: true,
    },
  ],
  companyWeekAPIs: [
    {
      path: "/api/admin/getCompaniDataYear",
      description: "Umsatzdaten eines Unternehmens nach Jahr",
      subVariables: [
        {
          variable: "nettoUmsObstGemKartof",
          description: "Netto-Umsatz Obst, Gemüse und Kartoffeln",
        },
        {
          variable: "nettoUmsSonstige",
          description: "Netto-Umsatz Sonstiges",
        },
        {
          variable: "summe",
          description: "Summe aller Umsätze dieser Woche.",
        },
      ],
      tooltipInfo: {},
      filterOption: false,
    },
    {
      path: "/api/admin/getCompaniCartValueYear",
      description: "Warenkorbwert eines Unternehmens nach Jahr",
      subVariables: [{ variable: "cartValue", description: "Warenkorbwert" }],
      tooltipInfo: {},
      filterOption: false,
    },
  ],
  companyQuarterAPIs: [
    {
      path: "/api/admin/getCompaniDataYearQuartal",
      description: "Umsatzdaten eines Unternehmens nach Jahr",
      subVariables: [
        {
          variable: "nettoUmsObstGemKartof",
          description: "Netto-Umsatz Obst, Gemüse und Kartoffeln",
        },
        {
          variable: "nettoUmsSonstige",
          description: "Netto-Umsatz Sonstiges",
        },
        {
          variable: "summe",
          description: "Summe aller Umsätze dieser Woche.",
        },
      ],
      tooltipInfo: {},
      filterOption: false,
    },
    {
      path: "/api/admin/getCompaniCartValueYearQuartal",
      description: "Warenkorbwert eines Unternehmens nach Jahr",
      subVariables: [{ variable: "cartValue", description: "Warenkorbwert" }],
      tooltipInfo: {},
      filterOption: false,
    },
  ],
  dataReqestsTemplate: {
    selectedAPI: {},
    Companyrequest: true,
    apiQuerryPath: null,
    apiQuerryData: {
      filter: {},
      year: null,
      email: null,
    },
    labels: [null],
    subVariable: [null],
    tooltipInfo: null,
  },
};

export default apiLib;

/* Template for API

        {
            path: "",
            description: "",
            subVariables: [
                {
                    variable: "",
                    description: "",
                },
                {
                    variable: "",
                    description: "",
                },
                {
                    variable: "",
                    description: "",
                },
            ],
            tooltipInfo: {
                variable: "count",
                appendText: " Einträge ergeben diesen Durchschnitt",
            },
            filterOption: true,
        },

*/
